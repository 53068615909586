.slick-dots > li {
  & > button {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 100%;
    height: 3px;
    padding: 0;
    text-indent: -999px;
    cursor: pointer;
    background-color: $primary;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;

    &::before {
      content: "";
    }
  }

  &.slick-active > button {
    opacity: 1;
  }
}
