&--bg {
  background-color: #3a3e3d;
}
&--padding {
  padding-top: 45px;
  padding-bottom: 100px;
}

.category_sec__button {
  &--size {
    width: 255px;
    height: 255px;
  }

  &--font {
    font-size: px-to-rem(32);
  }

  &--bg {
    background-color: #d8d8d8;
  }
  &:hover {
    &--bg {
      background-color: $gray-200;
    }
  }
}
