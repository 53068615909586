$img-size: 280px;

.img {
  &--size {
    width: $img-size;
    height: $img-size;
  }

  &--zindex {
    z-index: 2;
  }
}

.item--width {
  @extend .w-100;
  @extend .h-100;
  max-width: 400px;
}

.text-blue {
  color: #0054b1;
}

.article {
  &--padding {
    padding-top: calc($img-size / 2);
    padding-left: 65px;
    padding-right: 65px;
    padding-bottom: 35px;
  }

  &--margin-naive {
    margin-top: calc(-1 * ($img-size / 2));
  }

  &--bg {
    background-color: $gray-100;
  }
}
