& {
  @extend .position-relative;
  overflow: hidden;
}

$offest-px: 37px;
$content-height: 450px;

.img--size {
  height: 200px;
}

.background--gray {
  z-index: -1;
  background-color: $gray-300;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;

  &::before {
    content: " ";
    width: 0;
    height: 0;
    border-color: #fcfcfc transparent transparent;
    border-style: solid;
    border-width: 100vw 36vw 0;
    position: absolute;
    left: -50vw;
  }
}

@media (min-width: map-get($grid-breakpoints, md)) {
  &--height {
    height: $offest-px + $content-height;
  }

  .article_container--size {
    height: $content-height;
  }

  .article_container--position {
    bottom: -$offest-px;
  }

  .img--size {
    height: $content-height;
  }

  .img_container--position {
    position: absolute;
    top: -$offest-px;
  }
}
