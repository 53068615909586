.nav {
  --bs-nav-link-color: #5f6368;
  --bs-nav-link-padding-y: 0;
}

.footer__logo--size {
  max-width: 272px;
}

&.footer__border-y {
  border-top: 5px solid $gray-100;
  border-bottom: 12px solid $gray-800;
}

.footer__nav-link-smaller {
  font-size: px-to-rem(14);
}

.footer__social-icon--size {
  width: 32px;
  height: 32px;
  padding: 5px;
  color: black;

  &.icon_fb-padding {
    padding-top: 10px;
  }
}
