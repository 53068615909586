&--padding {
  padding-top: 150px;
  padding-bottom: 120px;
}

.search_icon--size {
  width: 28px;
}

.form_field--width {
  @media (min-width: map-get($grid-breakpoints, lg)) {
    width: 25%;
  }
}
