&--bg {
  background-color: $gray-100;
}

$narrow-narrow-height: 600px;

.carousel_inner--height {
  height: $narrow-narrow-height;
}

.carousel_narrow__contorl,
.carousel_narrow__contorl {
  $control-size: 64px;

  &--bg {
    background-color: $gray-200;
  }

  &--size {
    @extend .rounded-circle;
    width: $control-size;
    height: $control-size;
  }

  &_prev--position {
    top: calc(($narrow-narrow-height - $control-size) / 2);
    @media (min-width: map-get($container-max-widths, xxl)) {
      left: -$control-size;
    }
  }

  &_next--position {
    top: calc(($narrow-narrow-height - $control-size) / 2);
    @media (min-width: map-get($container-max-widths, xxl)) {
      right: -$control-size;
    }
  }
}
