&.theme_a {
  .title {
    &--decoration {
      @extend .position-relative;
      margin-bottom: 20px;
      padding-bottom: 10px;

      &:after {
        @extend .position-absolute;
        content: " ";
        background-color: $gray-400;
        height: 2px;
        left: 0;
        right: 0;
        bottom: -8px;
      }

      &:before {
        @extend .position-absolute;
        content: " ";
        background-color: $gray-400;
        height: 12px;
        @extend .rounded-circle;
        width: 12px;
        left: 0;
        bottom: -14px;
      }

      @media (min-width: map-get($grid-breakpoints, md)) {
        &-lg-reverse {
          &::before {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }

  .text--color-gray {
    color: $gray-400;
  }
}
