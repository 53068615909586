$font-family-base: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$primary: #70ba01;
$secondary: #666666;
$info: #0065f2;

$gray-100: #f6f6f6;
$gray-200: #d8d8d8;
$gray-300: #e7e7e7;
$gray-400: #8e8e93;
$gray-700: #5f6368;
$gray-800: #494949;

/* font */
$h2-font-size: px-to-rem(46);
$h3-font-size: px-to-rem(36);
$h5-font-size: px-to-rem(18);

$border-radius: 3px;

/* button */
$btn-padding-x-lg: 40px;

/* list */
$list-group-color: $secondary;
$list-group-action-color: black;

/* navbar */
$navbar-padding-y: 22px;
// $navbar-padding-x: 17px;
$navbar-brand-padding-y: 2px;
$navbar-dark-color: rgba(white, 0.8);
$navbar-nav-link-padding-x: 17px;

/* card */
$card-border-radius: 10px;
// $card-border-color: $brand-blue;
// $card-cap-bg: white;

/* breadcremb */
$breadcrumb-divider: (">");
$breadcrumb-divider-color: rgba(0, 101, 242, 0.4);
$breadcrumb-item-padding-x: 20px;
$breadcrumb-padding-y: 32px;
$breadcrumb-margin-bottom: calc(50px - 32px);

/* pagination */
$pagination-padding-y: 0rem;
$pagination-padding-x: 12px;
$pagination-font-size: 1.25rem;
$pagination-border-color: #979797;
$pagination-color: black;
$pagination-focus-color: white;
$pagination-focus-bg: $gray-200;
$pagination-hover-color: white;
$pagination-hover-bg: $gray-200;
$pagination-active-color: white;
$pagination-active-bg: $secondary;
$pagination-focus-box-shadow: 0 0 0 0.25rem $gray-100;

/* pill */
$nav-pills-link-active-bg: transparent;
$nav-pills-link-active-color: $primary;

/* form */
$input-bg: rgba(255, 255, 255, 0.8);
$input-focus-bg: white;

$accordion-border-width: 0px;
$accordion-inner-border-radius: 0px;
$accordion-border-radius: 0px;
$accordion-button-bg: $gray-100;
$accordion-button-active-bg: $gray-100;
$accordion-color: black;
$accordion-button-active-color: shade-color(black, 10%);
